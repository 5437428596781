* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root {
    --primary-color: grey;
    --secondary-color: black;
    --active-color: #00b055ff;
    --light-grey: #f9f9f9ff;
    --dark-grey: #919191ff;
    --category-color: #ff3519ff;
    --text-color : #fff;
    --fav-follow-color: #00b055ff;
    --blocked-color: #ff3519ff;
}

@media only screen and (max-width: 400px) {
    :root {
        font-size: 10px;
    }
}

.container {
    max-width: 100vw;
    background-color: var(--light-grey);
}

.flex-center-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.flex-center-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.flex-apart {
    display: flex;
    justify-content: space-between;
}

.flex {
    display: flex;
    align-items: center;
}

.flex-col {
    display: flex;
    flex-direction: column;
}

.title-text {
    font-size: 1rem;
    color: var(--secondary-color);
    font-weight: 600;
}

.description-text {
    font-size: 1.5rem;
    color: var(--text-color);
}

.button-extra-child {
    position: absolute;
    bottom: 10px;
    right: 10px;
}

.active {
    background-color: var(--active-color);
}

.collapsed {
    height: 0;
    transition: height 1s ease-in-out;
    overflow: hidden;
}

/* .decollapsed {
    animation: 3s decollapse;
}

@keyframes decollapse {
    0% {height: 0;}
    50% {height: 50%;}
    75% {height: 75%;}
    100% {height: auto;}
} */
