* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

html {
  scroll-behavior: smooth;
}

body {
  max-width: 100vw;
  background-color: #f2f2f2;
}

.app {
  width: 100vw;
  display: flex;
  height: 95vh;
  flex-direction: column;
  align-items: center;
  background-color: rgb(250, 250, 250);
}

html,
body {
  overscroll-behavior-y: contain;
  overflow-y: hidden;
}
body {
  position: fixed;
}
