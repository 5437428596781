.feed-post {
    width: 100%;
    border: 1px solid;
    border-radius: 10px;
    color: var(--text-color);
    overflow: hidden;
}

.feed-post-collapsed {
    
}

.feed-post > * {
    width: 100%;
}

.feed-post--poster-bloc {
    background-color: var(--dark-grey);
    padding-left: 0.6rem;
    padding-right: 0.2rem;
    
}

.feed-post--poster-bloc--user {
    align-items: center;
    font-size: 1.2rem;
    font-weight: 400;
}

.feed-post--poster-bloc--user--info {
    width: 70%;
}

.feed-post--poster-bloc--user--info > p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    
}

.feed-post--poster-bloc--user > img {
    aspect-ratio: 1/1;
    width: 10vw;
    border: 1px solid;
    border-radius: 50%;
    margin: 0.5rem;
    margin-right: 0;
    margin-left: 0;
    background-color: white;
}

.feed-post--content-bloc {
    color: var(--secondary-color);
    padding: 0.5rem;
}

.feed-post--content-bloc > h2 {
    font-size: 1.8rem;
}

.feed-post--poster-bloc--stats {
    /* display: flex */
}

.feed-post--poster-bloc--stats--icons {
    width: 50%;
    font-size: 1rem;
    gap: 1rem;
}

.feed-post--poster-bloc--stats--stat {
    gap: 0.3rem;
    justify-content: center;
    padding-bottom: 0.2rem;
}

.feed-post--poster-bloc--stats--stat > img {
    width: 1.4rem;
}

.feed-actions {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
}

.feed-actions img {
    height: 30px;
}

.extra-stars {
    display: flex;
    justify-content: space-evenly;
    width: 100%;

}

.extra-stars img {
    height: 30px;
}

.comments {
    width: 95%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: end;
}

.comments button {
    margin: 5px 0;
}

.comments textarea {
    border-radius: 10px;
    resize: none;
    width: 100%;
    font-size: 18px;
    padding: 5px;
    transition: height 0.2s ease-in-out; /* Smooth height transition */
    overflow: hidden; /* Hide the scrollbar */
    font-family: Arial;
}

.content {
    overflow: hidden;
    height: 0;
    transition: height 0.3s ease-in-out;
}

