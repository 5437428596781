.feed-page {
    display: flex;
    flex-direction: col;
}

.control-buttons {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
}

.control-buttons img {
    width: 50%;
}

.control-buttons {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    width: 100%;
    background-color: var(--background-color); /* Ensure the background is not transparent */
    padding: 10px 0;
    z-index: 1000; /* Ensure it's on top of other content */
    transition: all 0.3s ease;
}

.sticky {
    position: fixed;
    transition: position 0.3s ease;
    top: 0;
    padding: 0.5rem 1rem;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional: adds a shadow to the fixed bar */
}

.posts-list::before {
    content: '';
    height: 0;
    width: 100%;
    float: inline-end;
}

.posts-list-sticky::before {
    transition: height .3s ease;
    height: 100px;
}