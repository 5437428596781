.circled-button {
    border: 4px solid var(--secondary-color);
    border-radius: 50%;
    width: 18vw;
    aspect-ratio: 1/1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.circled-button img {
    width: 10vw;
}

