.wrapper-container {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  position: relative;
}

.wrapper-header {
  display: flex;
  height: 13%;
  width: 100%;
  gap: 2%;
  padding: 2%;
  justify-content: space-between;
}

.wrapper-button {
  aspect-ratio: 1/1;
}
.wrapper-content {
  display: flex;
  width: 100%;
  height: 90%;
  flex-direction: column;
  gap: 2%;
  padding: 2%;
  overflow-y: scroll;
}
.one-link {
  display: flex;
  height: 10%;
  width: 100%;
}
.open {
  height: 100%;
}
.wrapper-my-link-button {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.wrapper-link-cancel {
  height: 100%;
  aspect-ratio: 1/1;
}

.wrapper-input-link {
  display: flex;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 10%;
}
.wrapper-input-link > input {
  width: 100%;
}

.input-name-link {
  width: 100%;
  position: absolute;
  height: 10%;
  bottom: 12%;
}

.wrapper-link-content {
  display: flex;
  height: 100%;
  width: 100%;
}

.wrapper-link-search-content {
  display: flex;
  height: 100%;
  width: 100%;
}

.wrapper-open-share {
  display: flex;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 10%;
  gap: 2%;
  padding: 2%;
}
.wrapper-link-share-button {
  width: 24%;
  aspect-ratio: 1/1;
}

.wrapper-search-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 2%;
}
.wrapper-search-header {
  display: flex;
  height: 10%;
  width: 100%;
}
.wrapper-search-header > input {
  width: 100%;
  height: 100%;
  font-size: 20px;
  font-weight: 700;
}
.wrapper-search-button {
  height: 100%;
  aspect-ratio: 1/1;
}

.wrapper-search-body {
  width: 100%;
  height: 90%;
}

.image-dots {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-dots > img {
  object-fit: contain;
}

.visible-img {
  height: 60% !important;
}

.font-size-0 {
  font-size: 20px !important;
}

.font-size-1 {
  font-size: 40px !important;
}

.font-size-2 {
  font-size: 30px !important;
}

.font-size-3 {
  font-size: 20px !important;
}

.font-size-4 {
  font-size: 15px !important;
}

.font-size-5 {
  font-size: 10px !important;
}

.font-style-0 {
  font-weight: auto;
  color: black;
}

.font-style-1 {
  font-weight: bold !important;
}

.font-style-2 {
  font-weight: bold !important;
  color: rgb(53, 53, 53);
}

.font-style-3 {
  font-weight: bold !important;
  color: rgb(103, 103, 103);
}

.font-style-4 {
  font-weight: bold !important;
  color: rgb(255, 255, 255);
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: black;
}

.font-style-5 {
  font-weight: bold !important;
  color: rgb(255, 255, 255);
}
