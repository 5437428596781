.top-buttons {
    display: flex;
    justify-content: space-around;
    padding: 10px 0;
}

.user-feed {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.user-feed > div {
    margin: 10px 0;
    width: 95%;
}

.second-buttons {
    display: flex;
    justify-content: space-around;
    padding: 10px 0;
}