:root {
  --background-color: #f9f9f9ff;
  --border-dark: #999999ff;
  --border-light: #f2f2f2ff;
  --validate-valid: #00b055ff;
  --validate-invalid: #ff3519ff;
  --button-idle: #ccccccff;
  --button-active: #e6e6e6ff;
}

body {
  background-color: var(--background-color);
}

.sidebar {
  position: fixed;
  /* Changed to fixed to ensure it stays in view */
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 600;
  transition: transform 0.6s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: hideSoon 1s ease-in-out;
}

.sidebar-landscape {
  animation: hideSoonLandscape 1s ease-in-out;
}

@keyframes hideSoon {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(95%);
  }
}

@keyframes hideSoonLandscape {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-95%);
  }
}

.sidebar.hidden {
  transform: translateX(95%);
}

.sidebar.visible {
  transform: translateX(0);
}

.sidebar button {
  height: 11.11vh;
  width: 11.11vh;
  /*border-top: var(--border-light) 3px solid;
  border-bottom: var(--border-dark) 3px solid;
  border-left: var(--border-dark) 3px solid;
  border-right: var(--border-light) 3px solid;
  background: var(--button-idle);
  */
  /* display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer; */
}

/* User i zvonce dimenzije */
#root > div > div.sidebar.visible > button:nth-child(6) > img,
#root > div > div.sidebar.visible > button:nth-child(1) > img {
  width: 55%;
}

#root > div > div.definePin-container > div > div:nth-child(4) > button > img {
  width: 80%;
}

.sidebar button img {
  width: 70%;
}

@media (min-width: 700px) {
  .sidebar {
    position: fixed;
    /* Changed to fixed to ensure it stays in view */
    left: 0;
    top: 0;
    z-index: 100;
    transition: transform 0.6s ease;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100vw;
    height: 11.11vw;
  }

  .sidebar button {
    width: 11.11vw;
    height: 11.11vw;
  }
}

@media (max-width: 700px) {
  .sidebar.hidden {
    transform: translateX(95%);
  }
  .sidebar.visible {
    transform: translateX(0);
  }
}

@media (min-width: 700px) {
  .sidebar.hidden {
    transform: translateY(-95%);
  }
  .sidebar.visible {
    transform: translateY(0);
  }
}

.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(5px);
  z-index: 599;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.6s ease;
}

.mask.hidden {
  opacity: 0;
  pointer-events: none;
}

.mask.visible {
  opacity: 1;
  pointer-events: all;
}
