.login-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 100vh;
  width: 100%;
  padding: 5% 15%;
  gap: 1%;
}

.login-button {
  height: 10%;
  width: 100%;
  margin-top: 5%;
}

.login-wrapper > img {
  align-self: flex-start;
  width: 10%;
  aspect-ratio: 1/1;
  margin-top: 5%;
}

.input-key {
  display: flex;
  width: 100%;
  gap: 1%;
}

.input-key > input {
  width: calc(100% / 6);
  height: 11vw;
  text-align: center;
}

.input-mail {
  width: 100%;
  height: 10vw;
  padding: 0 2%;
}

input:focus {
  outline: none;
}

.register-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 2%;
  height: 63%;
  width: 100%;
}

.register-wrapper-left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
  height: 100%;
  padding: 0% 5%;
}
.register-wrapper-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 50%;
  padding: 0% 5%;
}
.register-wrapper-right > img {
  height: 5%;
  object-fit: contain;
}
.register-wrapper-right > input {
  height: 10%;
}

.register-button {
  width: 100%;
  height: 15%;
}

.register-button-img {
  display: flex;
  justify-content: center;
  gap: 5%;
  width: 100%;
  height: 100%;
  align-items: center;
}
.register-button-img > img:first-child {
  height: 40%;
  aspect-ratio: 1/1;
}

.register-text {
  display: flex;
  flex-direction: column;
  gap: 10%;
  border: solid #999999ff 2px;
  padding: 3%;
  overflow-y: scroll;
  height: 50%;
  width: 100%;
}

.not-correct-content {
  color: red;
}

.countdown-span {
  color: red;
  height: 20%;
  width: 50%;
  margin-bottom: 10%;
  font-size: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.info-login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30%;
  position: relative;
}

.login-click-img {
  width: 200px;
  cursor: pointer;
}

.info-login-collapse {
  display: none;
  height: 40%;
}

.info-login-collapse {
  border: 1px solid gainsboro;
  background-color: white;
  padding: 10px;
  position: relative;
}

.info-login-collapse p > img {
  height: 3vh;
}

.info-login-collapse p {
  font-size: 15px;
  padding: 5%;
  overflow-y: auto;
  height: 100%;
}

.cancel-message-login {
  position: absolute;
  top: -5%;
  right: -5%;
  height: 10%;
  aspect-ratio: 1/1;
}
.cancel-message-login > img {
  width: 100%;
  height: 100%;
}

@media only screen and (min-height: 1000px) {
  .info-login-collapse p {
    font-size: 2.6cqmin;
  }

  .info-login {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90%;
  }

  .info-login > img {
    width: 200px;
    cursor: pointer;
  }

  .info-login-collapse {
    display: none;
    height: 50vh;
  }
}

@media only screen and (max-width: 730px) {
  .login-wrapper-landscape {
    display: none;
  }
}

@media only screen and (min-width: 630px) {
  .login-button {
    height: 15% !important;
    margin-top: 10%;
  }
}

@media only screen and (min-width: 460px) {
  .login-wrapper-landscape {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 5%;
  }

  .landscape-info {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    height: 100%;
  }

  .login-form-landscape {
    width: 50%;
    height: 70%;
  }

  .login-form-landscape > input {
    height: 60px;
    margin-bottom: 25px;
    font-size: 22px;
  }

  .input-key > input {
    height: 50px;
    font-size: 22px;
  }

  .login-button > button {
    height: 100%;
    width: 90%;
    margin: auto;
  }

  .landscape-pair {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }
  .scrollable-content {
    height: 100%; /* Set the height according to your design */
    overflow-y: scroll;
  }

  .info-login-collapse {
    height: 90vh;
  }

  .register-wrapper {
    display: flex;
    flex-direction: column;

    align-items: normal;
  }

  .register-wrapper-top {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
  }

  .register-wrapper-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .register-button > button,
  .login-button > button {
    margin: 0px;
    width: 100%;
  }

  .register-button {
    display: flex;
    justify-content: start;
    align-items: center;
  }

  .login-button {
    height: 10%;
    display: flex;
    justify-content: end;
    align-items: center;
  }

  .reg {
    margin: 0;
  }
}

.font-size-0 {
  font-size: 20px !important;
}

.font-size-1 {
  font-size: 40px !important;
}

.font-size-2 {
  font-size: 30px !important;
}

.font-size-3 {
  font-size: 20px !important;
}

.font-size-4 {
  font-size: 15px !important;
}

.font-size-5 {
  font-size: 10px !important;
}

.font-style-0 {
  font-weight: auto;
  color: black;
}

.font-style-1 {
  font-weight: bold !important;
}

.font-style-2 {
  font-weight: bold !important;
  color: rgb(53, 53, 53);
}

.font-style-3 {
  font-weight: bold !important;
  color: rgb(103, 103, 103);
}

.font-style-4 {
  font-weight: bold !important;
  color: rgb(255, 255, 255);
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: black;
}

.font-style-5 {
  font-weight: bold !important;
  color: rgb(255, 255, 255);
}

.info-login-collapse {
  display: none;
  height: 40%;
  position: relative; /* Ensure the modal is positioned relative to its container */
  padding: 10px;
  border: 1px solid gainsboro;
  background-color: white;
}

/* Adjust the position of the cancel button */
.cancel-message-login {
  position: absolute;
  top: 10px; /* Adjust this value as needed */
  right: 10px; /* Adjust this value as needed */
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.cancel-message-login > img {
  width: 100%;
  height: 100%;
}
