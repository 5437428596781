.user-page {
    width: 95vw;
    margin: 0 auto;
    margin-top: 2rem;
    gap: 2rem;
}

.user-page--head-icons {
    width: 100%;
}

.head-icon-bordered {
    border: 6px solid black;
    border-radius: 50%;
    aspect-ratio: 1/1;
    width: 7.3rem;
    margin: 1rem 0;
}

.icon-img {
    width: 4.2rem;
}

.user-page--details {
    width: 80%;
}

.user-page--details > ul {
    color: grey;
    font-size: 1.4rem;
    gap: 0.6rem;
    list-style: none;
}

.user-page--contact-btns {
    width: 100%;
}

.contact-btn {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    width: 6rem;
    height: 6rem;
    border: 3px solid black;
    border-radius: 50%;
}

.contact-btn--img {
    width: 4rem;
}