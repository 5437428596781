.wrapper-logo {
  height: 90vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 5%;
  gap: 5%;
}

.android-apple {
  display: flex;
  width: 80%;
  height: 20%;
  justify-content: space-between;
}
.android-apple > img {
  height: 100%;
  width: 33%;
  object-fit: contain;
}

.logo {
  width: 90%;
  height: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo > img {
  height: 100%;
  object-fit: contain;
}

.wrapper-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.android-apple img {
  width: 100px; /* Adjust size as needed */
  height: 100px; /* Adjust size as needed */
  cursor: pointer;
  margin: 10px;
}
.youtube-link {
  display: flex;
  width: 80%;
  height: 20%;
  justify-content: space-between;
}

.youtube-link > img {
  height: 100%;
  width: 33%;
  object-fit: contain;
}

.glow {
  animation: animatePhoneLogo infinite 2s;
}

@keyframes animatePhoneLogo {
  0% {
    filter: invert(25%) sepia(75%) saturate(1000%) hue-rotate(90deg)
      brightness(100%) contrast(100%);
  }
  50% {
    filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(0%)
      contrast(0%);
  }
  100% {
    filter: invert(25%) sepia(75%) saturate(1000%) hue-rotate(90deg)
      brightness(100%) contrast(100%);
  }
}

@media only screen and (max-width: 480px) {
  .wrapper-logo-mobile {
    display: none;
  }
}

@media only screen and (min-width: 480px) {
  .wrapper-logo {
    display: none;
  }

  .wrapper-logo-mobile {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
  }

  .wrapper-logo-mobile-left {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .wrapper-logo-mobile-left > div {
    margin: 50px 0;
  }

  .wrapper-logo-mobile .logo {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .wrapper-logo-mobile .logo > img {
    width: 40vw;
    height: auto;
  }
}

@media only screen and (min-width: 800px) {
  .wrapper-logo-mobile .logo > img {
    width: 30vw;
    height: auto;
  }
  .android-apple img {
    width: 200px;
  }
}
